/* custom theme */
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-disclaimer-text li,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-disclaimer-text p {
  font-size: smaller;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-rangeslider-category p {
  font-size: small;
}

.dwcc-cookieconsent.dwcc-theme-allampapir {
  font-family: MyriadPro, MyriadPro-Regular, 'Myriad Pro Regular','Myriad Pro', 'PT Sans', Helvetica, Arial, sans-serif;
  animation: dwccFadeIn ease 1s;
  background-color: #003a70;
  border-color: #003a70;
  color: #fff;
}
.dwcc-cookieconsent.dwcc-theme-allampapir li,
.dwcc-cookieconsent.dwcc-theme-allampapir h1,
.dwcc-cookieconsent.dwcc-theme-allampapir h2,
.dwcc-cookieconsent.dwcc-theme-allampapir h3,
.dwcc-cookieconsent.dwcc-theme-allampapir h4,
.dwcc-cookieconsent.dwcc-theme-allampapir h5,
.dwcc-cookieconsent.dwcc-theme-allampapir p {
  color: inherit;
}


.dwcc-cookieconsent.dwcc-theme-allampapir [role=tooltip] {
  color: #000;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-icon-info {
  filter: invert(1);
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-button-secondary,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-button {
  background-color: #fff;
  font-weight: 600;
  color: #003a70;
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 0.75em 1.5em;
  line-height: normal;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.3s ease;
  text-transform: none;
  font-size: 1.1em;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-button-secondary {
  background: transparent;
  color: #fff;
}


.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-button:focus,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-button:hover {
  border: 2px solid #fff;
  color: #000;
}
.dwcc-cookieconsent.dwcc-theme-allampapir a {
  color: #fff;
  font-weight: 500;
}

.dwcc-cookieconsent.dwcc-theme-allampapir a:focus,
.dwcc-cookieconsent.dwcc-theme-allampapir a:hover {
  color: #fff;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies  {
  color: #000;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies a {
  color: #003a70;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies a:hover {
  color: #003a70;
}


.dwcc-cookieconsent.dwcc-theme-allampapir  .dwcc-box_cookies table .links a {
  font-weight: 500;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies table .links a::before {
    content: '- ';
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-link,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-btn.dwcc-block-link {
  color: #fff;
  border-bottom: 2px solid #bbb;
  font-size: 14px;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-link:hover,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-btn.dwcc-block-link:hover {
  border-bottom: 2px solid #fff;
  text-decoration: none;
}


.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies li a:hover,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies table a:hover,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies p a:hover {
  text-decoration: underline;
}

.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider::-webkit-slider-thumb {
  background: #FFFFFF;
  border: 1px solid #d8d8d8;
  box-shadow: 1px 1px 1px #353535;
}

.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider::-moz-range-thumb {
  background: #FFFFFF;
  border: 1px solid #d8d8d8;
  box-shadow: 1px 1px 1px #353535;
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider::-ms-thumb {
  background: #FFFFFF;
  border: 1px solid #d8d8d8;
  box-shadow: 1px 1px 1px #353535;
}






.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-inner::-webkit-scrollbar-track,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar-track {
  border-radius: 6px;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #003a70;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies .dwcc-cookies-text::-webkit-scrollbar {
  width: 14px;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-inner::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-inner::-webkit-scrollbar {
  width: 8px;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-rangeslider-label {
  font-size: 14px;
}

.dwcc-cookieconsent.dwcc-theme-allampapir p a {
  border-bottom: 0 none;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-disclaimer-text h1 {
  text-transform: none;
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider::-webkit-slider-runnable-track {
  background: rgba(255,255,255, 0.3);
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider:focus::-webkit-slider-runnable-track {
  background: rgba(255,255,255, 0.4);
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider::-moz-range-track {
  background: rgba(255,255,255, 0.3);
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider:focus::-moz-range-track {
  background: rgba(255,255,255, 0.4);
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider::-ms-fill-lower {
  background: rgba(255,255,255, 0.3);
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider::-ms-fill-upper {
  background: rgba(255,255,255, 0.3);
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider:focus::-ms-fill-lower {
  background: rgba(255,255,255, 0.4);
}
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider:focus::-ms-fill-upper {
  background: rgba(255,255,255, 0.4);
}

.dwcc-cookieconsent.dwcc-theme-allampapir button:focus,
.dwcc-cookieconsent.dwcc-theme-allampapir a:focus,
.dwcc-cookieconsent.dwcc-theme-allampapir input[type=range].dwcc-rangeslider:focus {
  outline: 1px dashed rgba(255,255,255, 0.75);
}


.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies button:focus,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies a:focus {
  outline: 1px dashed rgba(127,127,127, 0.75);
}



.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-rangeslider-category::before,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-rangeslider-category::after {
  background-color: rgba(255,255,255, 0.3);
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-close {
  /* border-radius: 0; */
  background-color: transparent;
  outline-offset: 0px;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-close::after,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-close::before {
  background-color: #fff;
  border-bottom-color: #eee;
  border-top-color: #eee;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies .dwcc-close{
  background-color: transparent;
}
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies .dwcc-close::after,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-box_cookies .dwcc-close::before {
  background-color: #003a70;
  border-top-color: #003a70;
  border-bottom-color: #003a70;
  box-shadow: none;
}

.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-close:focus,
.dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-close:hover {
  background-color: rgba(0,0,0, 0.1);
}

.dwcc-restricted_3rdparty-content-hidden {
  display: none !important;
}
.dwcc-restricted_3rdparty-content {
    max-width: 600px;
    margin: 2em auto 4em auto;
}
.dwcc-restricted_3rdparty-content > div {
  margin: 0 2em;
}
.dwcc-restricted_3rdparty-content h4 {
  font-size: 1.3em;
}
.dwcc-restricted_3rdparty-content p {
  font-size: 1.1em;
}
.map .dwcc-restricted_3rdparty-content h4 {
  font-size: 1.2em;
}
.dwcc-restricted_3rdparty-content-hidden {
  display: none !important;
}


@media screen and (max-width: 800px) {
  .dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-inner .dwcc-disclaimer-text h1 {
    font-size: 18px !important;
    font-weight: 600;
  }

  .dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-button-secondary, .dwcc-cookieconsent.dwcc-theme-allampapir .dwcc-button {
    font-size: 14px;
    padding: 0.75em 1.5em;
  }
}