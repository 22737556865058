/* range switch */


.dwcc-cookieconsent .dwcc-type-category .switch {
  margin: 7px 0;
  float: right;
  position: relative;
  height: 26px;
  width: 120px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
}

.dwcc-cookieconsent .dwcc-type-category .switch-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 58px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;

}
.dwcc-cookieconsent .dwcc-type-category .switch-label:hover {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  color: rgba(255, 255, 255, 1);
}
.dwcc-cookieconsent .dwcc-type-category .switch-label:active {
  font-weight: bold;
}

.dwcc-cookieconsent .dwcc-type-category .switch-label-off {
  padding-left: 2px;
}

.dwcc-cookieconsent .dwcc-type-category .switch-label-on {
  padding-right: 2px;
}


.dwcc-cookieconsent .dwcc-type-category .switch-input {
  display: none;
}
.dwcc-cookieconsent .dwcc-type-category .switch-input:checked + .switch-label {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}
.dwcc-cookieconsent .dwcc-type-category .switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 60px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.dwcc-cookieconsent .dwcc-type-category .switch-selection {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  display: block;
  width: 58px;
  height: 22px;
  border-radius: 3px;
  background-color: #65bd63;
  background-image: linear-gradient(to top, #9dd993, #65bd63);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);
  transition: left 0.15s ease-out;
}
.dwcc-cookieconsent .dwcc-type-category .switch-blue .switch-selection {
  background-color: #3aa2d0;
  background-image: linear-gradient(to top, #4fc9ee, #3aa2d0);
}
.dwcc-cookieconsent .dwcc-type-category .switch-yellow .switch-selection {
  background-color: #c4bb61;
  background-image: linear-gradient(to top, #e0dd94, #c4bb61);
}

.dwcc-cookieconsent .dwcc-type-category .switch-white .switch-selection {
  background-color: #c4bb61;
  background-image: linear-gradient(to top, #ffffff, #eeeeee);
}
