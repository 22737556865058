/* range sliders */



.dwcc-cookieconsent input[type=range].dwcc-rangeslider {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background-color: transparent;
  transition: background-color 300ms;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-moz-focus-outer {
  outline: none;
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgba(155,155,155, 0.3);
  border-radius: 50px;
  border: 0px solid;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus::-webkit-slider-runnable-track {
  background: rgba(155,155,155, 0.5);
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #353535;
  border: 1px solid #222;
  background: #444;
  height: 31px;
  width: 32px;
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus::-webkit-slider-thumb {
  border: 1px solid #111
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-moz-range-thumb {
  box-shadow: 1px 1px 1px #353535;
  border: 1px solid #222;
  background: #444;
  height: 31px;
  width: 32px;
  border-radius: 50px;
  cursor: pointer;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus::-moz-range-thumb {
  border: 1px solid #111
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgba(155,155,155, 0.3);
  border-radius: 50px;
  border: 0px solid;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus::-moz-range-track {
  background: rgba(155,155,155, 0.5);
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-ms-tooltip {
  display: none;
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  overflow: visible;
  border-color: transparent;
  border-width: 15px 0;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus {
  outline: 2px dashed rgba(127,127,127, 0.5);
  outline-color: invert;
  outline-offset: 12px;
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-ms-fill-lower {
  background: rgba(155,155,155, 0.3);
  border: 0px solid;
  border-radius: 100px;
  overflow: visible;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus::-ms-fill-lower {
  background: rgba(155,155,155, 0.5);
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-ms-fill-upper {
  background: rgba(155,155,155, 0.3);
  border: 0px solid;
  border-radius: 100px;
  overflow: visible;
}
.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus::-ms-fill-upper {
  background: rgba(155,155,155, 0.5);
}


.dwcc-cookieconsent input[type=range].dwcc-rangeslider::-ms-thumb {
  border: 1px solid #222;
  background: #444;
  box-shadow: 1px 1px 1px #353535;
  height: 31px;
  width: 32px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 100;
}

.dwcc-cookieconsent input[type=range].dwcc-rangeslider:focus::-ms-thumb {
  border: 1px solid #111;
}
@supports (-ms-ime-align:auto) {
  .dwcc-cookieconsent input[type=range].dwcc-rangeslider::-ms-thumb {
    margin-bottom: -11px;
  }
}



.dwcc-cookieconsent .dwcc-type-rangeslider {
  margin-top: 20px;
  width: 100%;
  float: left;
}
.dwcc-cookieconsent .dwcc-rangeslider-wrp.vertical {
  float: left;
  width: 1px;
  height: 1px;
  overflow: visible;
  padding: 0 24px;
}
.dwcc-cookieconsent .dwcc-rangeslider-wrp.vertical .dwcc-rangeslider {
    transform: rotate(90deg);
    transform-origin: 0% 50%;
    position: relative;
    display: block;
    margin: 0;
    /* width: 232px; */
    width: 302px;

  }
@supports (-ms-ime-align:auto) {
  .dwcc-cookieconsent .dwcc-rangeslider-wrp.vertical .dwcc-rangeslider {
    top: -20px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dwcc-cookieconsent .dwcc-rangeslider-wrp.vertical .dwcc-rangeslider {
    top: -40px;
    left: -7px;
  }
}
.dwcc-cookieconsent .dwcc-rangeslider-categories {
  float: left;
  margin-left: 20px;
  width: calc(43% - 100px);
}

.dwcc-cookieconsent .dwcc-rangeslider-category {
  min-height: 90px;
  position: relative;
}

.dwcc-cookieconsent .dwcc-rangeslider-category::before,
.dwcc-cookieconsent .dwcc-rangeslider-category::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background-color: rgba(155,155,155, 0.4);
  left: -26px;
  top: 20px;
  z-index: -1;
}
.dwcc-cookieconsent .dwcc-rangeslider-category::after {
  left: -71px;
}
.dwcc-cookieconsent .dwcc-rangeslider-category .dwcc-rangeslider-label {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  position: relative
}
.dwcc-cookieconsent .dwcc-rangeslider-category p {
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}
.dwcc-cookieconsent .dwcc-rangeslider-details {
  margin-left: 10%;
  margin-bottom: 1em;
  float: left;
  width: 45%;
}
.dwcc-cookieconsent .dwcc-rangeslider-details h2 {
  font-size: 1em;
  margin: 0;
}
.dwcc-cookieconsent .dwcc-rangeslider-details ul {
  margin: 10px 0;
  padding: 0 0 0 20px;
}
.dwcc-cookieconsent .dwcc-rangeslider-details ul li {
  font-size: 15px;
  list-style-type: square;
  margin-bottom: 5px;
}

.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="1"] .dwcc-rangeslider-category[data-val="2"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="1"] .dwcc-rangeslider-category[data-val="3"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="1"] .dwcc-rangeslider-category[data-val="4"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="1"] .dwcc-rangeslider-category[data-val="5"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="2"] .dwcc-rangeslider-category[data-val="3"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="2"] .dwcc-rangeslider-category[data-val="4"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="2"] .dwcc-rangeslider-category[data-val="5"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="3"] .dwcc-rangeslider-category[data-val="4"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="3"] .dwcc-rangeslider-category[data-val="5"],
.dwcc-cookieconsent .dwcc-rangeslider-categories[data-active="4"] .dwcc-rangeslider-category[data-val="5"]
{
  opacity: 0.65;
}


@media screen and (max-width: 800px) {
  .dwcc-cookieconsent .dwcc-rangeslider-categories {
    width: calc(100% - 80px);
    max-width: 400px;
  }
  .dwcc-cookieconsent .dwcc-rangeslider-category {
    /* height: 115px; */
    position: relative;
  }

  .dwcc-cookieconsent .dwcc-rangeslider-wrp.vertical .dwcc-rangeslider {
    margin: 0;
    /* width: 262px; */
    /* width: 302px; */
  }
  .dwcc-cookieconsent .dwcc-categories .dwcc-rangeslider-category .label {
    font-size: 14px;
  }
  .dwcc-cookieconsent .dwcc-categories .dwcc-rangeslider-category p {
    font-size: 13px;
  }
  .dwcc-cookieconsent .dwcc-rangeslider-details {
    float: none;
    clear: both;
    max-width: 500px;
    width: 100%;
    margin-left: 0px;
  }
  .dwcc-cookieconsent .dwcc-rangeslider-details h2 {
    font-size: 14px;
    margin-bottom: 0px;
    text-transform: none;
  }
  .dwcc-cookieconsent .dwcc-rangeslider-details ul,
  .dwcc-cookieconsent .dwcc-rangeslider-details li {
    display: inline;
    padding: 0;
  }
  .dwcc-cookieconsent .dwcc-rangeslider-details ul li {
    font-size: 13px;
    line-height: 1.3;
  }
  .dwcc-cookieconsent .dwcc-rangeslider-details ul {
    display: block;
    min-height: 60px;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .dwcc-cookieconsent .dwcc-rangeslider-details li::after {
    content: ', ';
  }
  .dwcc-cookieconsent .dwcc-rangeslider-details li:last-of-type::after {
    content: '';
  }
}
